import { Container, Row, Col, Card } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import google from '../google icon.png';
import './Review.css'; 
const reviews = [
  {
    name: "Raj Vardhan Singh",
    text: "I want to recommend the Data Science 360 course for everyone those want to make a career in the domain of data analytics or data science. The live sessions were interactive and enlightening. The experienced faculty was always there to help with doubts, and their real-world insights were invaluable. Affordable pricing, regular feedback, and industry case studies make this course a standout choice.",
    rating: 5,
  },
  {
    name: "Jeet Patel",
    text: "I recently completed the Data Science 360 course, and it was an incredible experience. The live interactive sessions were a game-changer, and the experienced faculty ensured we grasped complex concepts easily. Doubt resolution was swift, which kept my learning on track. This course's affordability is unbeatable, and the industry-relevant case studies truly enriched my understanding.",
    rating: 5,
  },
  {
    name: "Vandit",
    text: "I'm thrilled with the results from the Data Science 360 course. The live interactive sessions were both fun and educational. The experienced faculty and trainers were perfect in making complex concepts understandable. Doubt resolution was swift, and the affordability of the course made it accessible to all. The industry-relevant case studies were a fantastic practical touch.",
    rating: 5,
  },
  {
    name: "Deepu Bisht",
    text: "As someone with an arts background, I am new to the IT industry and recently enrolled in the 'Data Science 360 Program' course offered by Brainalyst. Initially, I struggled to comprehend mathematical concepts, basic coding, and other technical aspects. However, with my mentor’s help and perseverance, I can now easily follow the classes. The course provides excellent teaching with practical live classes, and trainees should expect to put in sincere effort outside of the one-hour class sessions to fully engage with the well-structured curriculum.",
    rating: 5,
  },
  {
    name: "Aamna Raza",
    text: "Brainalyst's Data Science 360 Program is truly exceptional. The combination of practical live classes and the need for sincere efforts from trainees creates an immersive and impactful learning experience. Despite having an arts background and being new to the IT industry, I now find it easy to follow the classes thanks to my mentor’s guidance and perseverance.",
    rating: 5,
  },
  {
    name: "Himanshi Kanwat",
    text: "After enrolling in the Data Science 360 course, I was equipped with the necessary skills to pursue a career in Data Science by the team at Brainalyst. Thanks to my instructors guidance and knowledge and the placement team's exceptional placement support, I secured an                     analyst position with a well-respected Consulting firm.I am grateful to Brainalyst for their support. I recommend them as a comprehensive solution for anyone looking to acquire the essential skills to launch a          career in data science.",
    rating: 5,
  },
  {
    name: "Rajat Vashisth",
    text: " Brainalyst's Data Science 360 Program has been an exceptional learning experience. The instructors are passionate about the subject matter and do an excellent job of breaking down complex topics into digestible pieces. The curriculum is well-structured, providing a comprehensive understanding of the subject matter, and the support staff are always available to answer questions and offer guidance I would highly recommend this program to anyone looking to further their career                      in data science.",
    rating: 5,
  },
  {
    name: "Devanshi Gupta",
    text: "Data Science 360 is a game-changer. The live sessions were incredibly interactive and insightful. The experienced trainers provided real-world insights that I found incredibly valuable. Doubt resolution was swift,and the competitive pricing made it accessible to all.The regular feedback system kept me engaged.",
    rating: 5,
  },
  {
    name: "Nilay Kundap",
    text: " In hopes of utilising my free time, I downloaded the Brainalyst app and enrolled myself into the Data Science 360 Course. It was an amazing decision! I’m learning a lot through the course. The instructors are well experienced. This affordable course really will help me get my dream job.",
    rating: 5,
  },
  {
    name: "Ekta Kumari",
    text: " I recently enrolled in the Data Science 360 Program course offered by Brainalyst, and my experience thus far has been excellent.Brainalyst provides best-in-class content and the instructors are extremely knowledgeable. They have provided me with all the tools I need to succeed in this field, including detailed lectures, hands-on projects, and career guidance. I highly recommend Brainalyst for anyone looking to get into data science!",
    rating: 5,
  },
  
];

const ReviewCard = ({ name, text, rating }) => {
  return (
    <Col className="review-card" style={{marginBottom: '3rem'}}>
      <Card className="h-100">
        <Card.Body className="card-body">
          <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
            <div>
        <img src={google} alt="Google" style={{ height: '2rem', objectFit: 'contain', opacity: '1' }} />
        </div>
        <div style={{display: 'flex' ,flexDirection: 'column'}}>
          <div className="text-center mb-3">            
            <h5 className="mt-2 mb-0" style={{marginLeft: '2px'}}>{name}</h5>
          </div>          
          </div>          
          </div>
          <div className="d-flex justify-content-center mb-3" style={{marginLeft: '2rem'}}>
            <ul style={{ listStyle: "none", display: "flex", padding: 0 }}>
              {Array(rating).fill().map((_, i) => (
                <li key={i}>
                  <i className="fa-solid fa-star" style={{ color: "#f2d202", marginRight: '4px' }}></i>
                </li>
              ))}
            </ul>
          </div>
          <Card.Text style={{ font: "13px", fontFamily: "Margarine sans-serif", fontWeight: "400", fontStyle: "normal", marginBottom: '2rem' }}>
            {text}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const Review = () => {
  return (
    <section className="review" id="review">
      <Container className="review-container">
        <div className="course-title text-center">
          <h2
            style={{
              fontFamily: "Margarine sans-serif",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            Student's Reviews
          </h2>
        </div>
        <Carousel fade indicators={true} className="review-carousel">
          {reviews.map((review, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center">
                <ReviewCard name={review.name} text={review.text} rating={review.rating} />
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};
