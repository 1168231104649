import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import bonusImg2 from "../img/internship.svg";
import bonusImg3 from "../img/bonus reffral.svg";
import bonusImg4 from "../img/certificate.svg";
import bonusImg5 from "../img/coding.svg";
import bonusImg6 from "../img/life time acess.svg";
import "./Bonus.css";


export const Bonus = () => {
  return (
    <section className="bonus" id="bonus">
      <hr />
      <Container>
        <Row>
          <div className="course-title">
            <h2
              style={{
                fontWeight: "600",
                fontFamily: "Margarine sans-serif",
                fontStyle: "normal",
              }}
            >
              Additional Bonus
            </h2>
          </div>

          {[
            {
              img: bonusImg2,
              alt:"bonusimg2",
              title: "Get a 100% Paid Internship*",
            },
            {
              img: bonusImg5,alt:"bonusimg5",
              title:
                "Access to Hackathons, Coding Competitions & Webinars On Completion of This Course",
            },
            {
              img: bonusImg4,alt:"bonusimg4",
              title: "Course Completion & Internship Certificate",
            },
            { img: bonusImg6,alt:"bonusimg6", title: "Life-Time LMS Access" },
            { img: bonusImg3,alt:"bonusimg3", title: "Referral Bonus" },
          ].map((bonus, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "3rem",
              }}
            >
              <Card className="course-card">
                <Card.Img
                  variant="top"
                  src={bonus.img}
                  alt="bonusImg"
                  className="course-img"
                  style={{ height: "7rem" }}
                />
                <Card.Body>
                  <Card.Title className="course-title">
                    {bonus.title}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
