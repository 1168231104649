import React, { useState,useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import emailjs from '@emailjs/browser';


const serviceId = "service_o0bp90e";
const templateId = "template_kvpqfec";

const CurriculumModal = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({ name: "", phone: "", email: "" });
  const [status, setStatus] = useState({});
  const formRef = useRef();

  

  const handleShow = () => {
    setShow(true);    
  };

  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   

    try {
      const emailResult = await emailjs.sendForm(serviceId, templateId, formRef.current, 'VWsEdichg5c76MiDG');
      console.log("Email sent successfully:", emailResult.text);
      setStatus({ success: true, message: 'Message sent successfully' });

      downloadPDF();
      handleClose();
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus({ success: false, message: 'Failed to send message' });
    }
  };

  const downloadPDF = () => {
    try {
      const pdfUrl = '/DATA SCIENCE 360 - Brochure (1).pdf';
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", "Curriculum-Brochure.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="fixed-button"
        style={{
          color: "#FFFFFF",
          background: "#808080cf",
          fontFamily: "Margarine sans-serif",
          fontWeight: "400",
          fontStyle: "normal",
          fontSize: "14px",
          position: "fixed",
          right: "10px",
          bottom: "10px",
          zIndex: "1000",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          padding: "10px 10px",
          width: "45%",
          height: "45px",
          border: 'none'
        }}
      >
        Download Curriculum <i className="fa-solid fa-download"></i>
      </Button>

      <Modal show={show} onHide={handleClose} style={{ marginTop: "65px" }}>
        <Modal.Header closeButton style={{ background: "rgb(0,123,255)" }}>
          <Modal.Title style={{ fontFamily: 'Margarine sans-serif', fontWeight: '400', fontStyle: 'normal' }}>Fill the Form to Download the Brochure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label style={{ color: "#121212" }}>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label style={{ color: "#121212" }}>Whatsapp No.</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label style={{ color: "#121212" }}>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: "10px" }}
            >
              Submit & Download
            </Button>
          </Form>
          {status.message && (
            <p style={{ marginTop: '10px', color: status.success ? 'green' : 'red' }}>{status.message}</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CurriculumModal;
