import { Container, Row, Col } from "react-bootstrap";
import certificate1 from "../img/certificate1.webp";
import certificate2 from "../img/certificate2.webp";

export const Cretificate = () => {
  return (
    <section className="certificate" id="certificate">
      <Container>
        <Row>
          <div className="course-title">
            <h2
              style={{
                fontFamily: "Margarine sans-serif",
                fontStyle: "normal",
              }}
            >Certificate
            </h2>
          </div>
          <hr />

          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexWrap: 'wrap',
              background: 'rgb(240 236 236 / 81%)',
            }}
          >
            <div>
              <img
                src={certificate1}
                alt="certificate1"
                style={{
                  maxWidth: "30rem",
                  maxHeight: "35em",
                  width: "20rem",
                  height:"26rem",
                  paddingTop: "10px",
                }}
              />
            </div>
            <div>
              <img
                src={certificate2}
                alt="certificate2"
                style={{
                  maxWidth: "30rem",
                  maxHeight: "35em",
                  width: "20rem",
                  paddingTop: "10px",
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
