import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Container,Row,Col} from 'react-bootstrap';
import './Book.css';
import book1 from '../img/MACHINE-LEARNING-COVER-PAGE-768x993_11zon.webp';
import book2 from '../img/mastering in pyton_11zon.webp';
import book3 from '../img/NATURAL-LANGUAGE-PROCESSING-COVER-768x994_11zon.webp';
import book4 from '../img/POWER-BI-COVER-PAGE-768x994_11zon.webp';
import book5 from '../img/POWER-BI-VISUALS-COVER-768x994_11zon.webp';
import book6 from '../img/R-BASIC-TO-ADVANCE-768x991_11zon.webp';
import book7 from '../img/SQL-BASIC-TO-ADVANCE-COVER-PAGE-768x994_11zon.webp';
import book8 from '../img/TIME-SERIES-IN-PYTHON-COVER-768x993_11zon.webp';
import book9 from '../img/VBA-COVER-PAGE-768x993_11zon.webp';
import book10 from '../img/VISUALIZATION-COVER-PAGE-768x987_11zon.webp';



export const Book=()=>{
    const responsive = {
        superLargeDekstop:{
        
            breakpoint:{max:4000,min:3000},
            items:5
        },
        desktop:{
            breakpoint:{max:3000,min:1024},
            items:3
        },
        tablet:{
            breakpoint:{max:1024,min:464},
            items:2
        },
        mobile:{
            breakpoint:{max:464,min:0},
            items:1
        }
    };
    return(
        <section className='book' id='book'>
            <Container>
                <Row>
                    <Col>
                    <div className="book-bx">
                        <h2 style={{fontFamily: "Margarine sans-serif",
                  fontStyle: 'normal'}}>Books</h2>
                  <div><h6 style={{color: 'aliceblue'}}>Your Path to Data Proficiency Starts Here</h6></div>
                        <hr />
                    <Carousel responsive={responsive} infinite={true} className="book-slider">
                        <div className="items"><img src={book1} alt="books1" width="160" height="206"/></div>
                        <div className="items"><img src={book2} alt="books2" width="160" height="206"/></div>
                        <div className="items"><img src={book3} alt="books3" width="160" height="206"/></div>
                        <div className="items"><img src={book4} alt="books4" width="160" height="206"/></div>
                        <div className="items"><img src={book5} alt="books5" width="160" height="206"/></div>
                        <div className="items"><img src={book6} alt="books6" width="160" height="206"/></div>
                        <div className="items"><img src={book7} alt="books7" width="160" height="206"/></div>
                        <div className="items"><img src={book8} alt="books8" width="160" height="206"/></div>
                        <div className="items"><img src={book9} alt="books9" width="160" height="206"/></div>
                        <div className="items"><img src={book10} alt="books10" width="160" height="206"/></div>
                                              

                    </Carousel>
                    </div>
                    </Col>
                </Row>
            </Container>
        </section>

    )
}