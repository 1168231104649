import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import student from "../img/student.svg";
import graduate from "../img/gradute.svg";
import experienced from "../img/working professional.svg";
import "./Course.css";

const cardData = [
  {
    src: student,
    alt: "Student",
    title: "Students",
    text: "It's an ideal choice for learners who want to start or transition into careers as a Data Analyst, Data Scientist, Business Analyst, or ML Engineer",
  },
  {
    src: graduate,
    alt: "Graduate",
    title: "Fresh Graduate",
    text: "Gain the knowledge and experience needed for your dream job in data analytics, data science, artificial intelligence, big data, or cloud computing",
  },
  {
    src: experienced,
    alt: "Experienced Professional",
    title: "Experienced Professional",
    text: "Dive into the exciting world of data science with a blend of essential tools, techniques, and real-world projects. Whether you’re launching your dream career or transitioning to roles like data analyst, data scientist, or ML engineer.",
  },
];

function Courses() {
  return (
    <section className="course" id="course">
      <Container>
        <Row className="justify-content-center">
          <div className="course-title">
            <h2
              style={{
                fontWeight: "400",
                fontFamily: "Margarine, sans-serif",
                fontStyle: "normal",
              }}
              className="animate__animated animate__fadeInLeft"
            >
              This Course Is Specially Curated for?
            </h2>
          </div>
          <hr />
          {cardData.map((card, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className="d-flex justify-content-center align-items-center"
              style={{ marginBottom: "3rem" }}
            >
              <Card className="card-container">
                <Card.Img
                  variant="top"
                  src={card.src}
                  alt={card.alt}
                  className="card-img"
                  width="160"
                  height="160"
                />
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <div className="card-overlay">
                    <Card.Text className="card-text">{card.text}</Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Courses;
