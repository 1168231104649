import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col } from 'react-bootstrap';
import mentor1 from '../img/sir2.png.webp';
import mentor2 from '../img/rohan.jpg_11zon.webp';
import mentor3 from '../img/Ratnesh.jpg.webp';
import mentor4 from '../img/Arpit.jpg_11zon.webp';
import mentor5 from '../img/Shweta.jpg.webp';
import mentor6 from '../img/shaurab-768x768.jpg.webp';
import './Expert.css';

export const Expert = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const mentors = [
        { src: mentor1, title: "FOUNDER - BRAINALYST", name: "Nitin Sharma" },
        { src: mentor2, title: "DATA SCIENTIST AT CBRE", name: "Rohan Saini" },
        { src: mentor3, title: "DATA SCIENTIST | PROBLEM SOLVER", name: "Ratnesh Kumar" },
        { src: mentor4, title: "CERTIFIED BIG DATA SCIENTIST", name: "Arpit Goel" },
        { src: mentor5, title: "DATA ENGINEER AT WALMART", name: "Sweta Agarwal" },
        { src: mentor6, title: "ASSOCIATE DIRECTOR @ UBS", name: "Saurabh Kulkarni" }
    ];

    return (
        <section className='expert' id='expert'>
            <Container>
                <Row>
                    <Col>
                        <div className="expert-bx">
                            <h2>Mentors & Experts</h2>
                            <hr />
                            <Carousel responsive={responsive} infinite={true} className="expert-slider">
                                {mentors.map((mentor, index) => (
                                    <div key={index} className="item">
                                        <img src={mentor.src} alt={`mentor-${index}`} width="200" height="200" />
                                        <h5>{mentor.title}</h5>
                                        <p>{mentor.name}</p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
