import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import ReactGA from 'react-ga';

const PopupForm = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({ name: '', phone: '', email: '' });
  const [status, setStatus] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(() => localStorage.getItem('formSubmitted') === 'true');

  useEffect(() => {
    if (!isSubmitted) {
      const timers = [
        setTimeout(() => setShow(true), 5000),
        setTimeout(() => setShow(true), 22000), 
        setTimeout(() => setShow(true), 52000),
      ];

      return () => timers.forEach(timer => clearTimeout(timer)); 
    }
  }, [isSubmitted]);

  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    ReactGA.event({
      category: 'User',
      action: 'Submitted Popup Form',
      label: formData.name,
    });

    try {
      await emailjs.sendForm('service_o0bp90e', 'template_kvpqfec', e.target, 'VWsEdichg5c76MiDG');
      setStatus({ success: true, message: 'Message sent successfully' });
      localStorage.setItem('formSubmitted', 'true');
      setIsSubmitted(true);
      handleClose();
      alert('Message sent successfully');
    } catch (error) {
      console.error('EmailJS send error:', error);
      setStatus({ success: false, message: 'Failed to send message' });
      alert('Failed to send message');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton style={{ color: "#FFFFFF", background: "#0056b3" }}>
        <Modal.Title style={{ fontFamily: 'Margarine sans-serif', fontWeight: '400', fontStyle: 'normal' }}>
          <h2>Join the Data Science & Analytics Revolution Now</h2>
          <p style={{ fontSize: '15px' }}>Grab that 100% paid internship* opportunity</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label style={{ color: '#121212' }}>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label style={{ color: '#121212' }}>Whatsapp No.</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label style={{ color: '#121212' }}>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" style={{ color: "#FFFFFF", background: "#0056b3", marginTop: '10px', fontFamily: 'Margarine sans-serif', fontWeight: '400', fontStyle: 'normal' }}>
            Submit
          </Button>
        </Form>
        {status.message && (
          <p style={{ marginTop: '10px', color: status.success ? 'green' : 'red' }}>{status.message}</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PopupForm;
