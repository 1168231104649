import Carousel from 'react-bootstrap/Carousel';
import './Carousel.css';
import B1 from "../img/b1_11zon.webp";
import B2 from '../img/b2_11zon.webp';
import B3 from '../img/b3_11zon.webp';

function IndividualIntervalsExample() {
  return (
    <section className='home' id='home'>
      <Carousel fade>
        <Carousel.Item interval={3000}>
          <img src={B1} alt="Carousel-First-Img" loading='lazy'/>
          <Carousel.Caption>
            <h3>Master Data Science and Analytics With Brainalyst's Data Science 360 Program</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img src={B2} alt="Carousel-Second-Img" loading='lazy'/>
          <Carousel.Caption>
            <h3>Start the Data Science 360 Course today and unlock a guaranteed paid internship* upon completion!</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img src={B3} alt="Carousel-Third-Img" loading='lazy'/>
          <Carousel.Caption>
            <h3>Transform your career with our affordable,interactive Data Science course featuring expert-led,dedicated classes</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
  );
}

export default IndividualIntervalsExample;
