import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import excelImg from "../img/excel image.svg";
import marcosImg from "../img/vba macros.svg";
import sqlDsImg from "../img/sql.svg";
import tableauImg from "../img/visualization.svg";
import powerBiImg from "../img/visualization power bi.svg";
import programingImg from "../img/machine learning.svg";
import programingImg1 from "../img/programing.svg";
import "./Curriculum.css"; 

const CurriculumSection = () => {
  return (
    <section className="curriculum" id="Curriculum">
      <Container className="two_elementor_element">
        <Row style={{ justifyContent: 'center' }}>         
          <div className="curriculum-title">
            <h2
              style={{
                fontWeight: "400",
                fontFamily: "Margarine sans-serif",
                fontStyle: "normal",
              }}
              >
              Curriculum
            </h2>
          </div>           
          <hr />
          {[
            { img: excelImg, alt: "excel", title: "Advanced Excel for Data Analytics", text: "Learn to use powerful Excel features like advanced formulas, pivot tables, and data cleaning tools to analyze and understand large sets of data" },
            { img: marcosImg, alt: "marcos", title: "VBA for Macros", text: "Discover how to write and use Visual Basic for Applications(VBA) to automate repetitive tasks in Excel,saving time and reducing errors" },
            { img: sqlDsImg, alt: "sql", title: "SQL for Data Analytics", text: " Understand how to write SQL queries to retrieve, organize, and analyze data from relational databases " },
            { img: tableauImg, alt: "tableau", title: "Data Analytics & Visualization with Tableau", text: "Get hands-on experience creating visual data reports and interactive dashboards with Tableau to make data insights easy to understand" },
            { img: powerBiImg, alt: "powerbi", title: "Data Analytics & Visualization with PowerBi", text: "Learn to use PowerBi to build dynamic data reports and dashboards, helping you visualize and interpret data trends and patterns" },           
            { img: programingImg1, alt: "programing", title: "Data Science with Python (Including Machine Learning)", text: "Explore data science with Python, learning how to handle data, perform analysis, and use machine learning techniques to build predictive models" },
            { img: programingImg, alt: "programingimg", title: "Data Science with R (Including Machine Learning)", text: "Get into data science with R by learning how to analyze data statistically and use machine learning to make predictions" },
            
          ].map((curriculum, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "3rem"
              }}
            >
              <Card className="curriculum-card">
                <Card.Img
                  variant="top"
                  src={curriculum.img}
                  alt="curriculumImg"
                  className="curriculum-img"
                />
                <Card.Body>
                  <Card.Title className="curriculum-title">
                    {curriculum.title}
                  </Card.Title>
                  <Card.Text className="curriculum-text">
                    {curriculum.text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default CurriculumSection;
