import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
import navicon1 from "../img/nav-icon1.svg";
import navicon2 from "../img/nav-icon2.svg";
import navicon3 from "../img/nav-icon3.svg";
import navicon4 from "../img/youtube.svg";

export const Foot = () => {
  return (
    <footer className="footer" id="footer" style={{ paddingBottom: "4rem" }}>
      <Container>
        <div
          style={{
            display: "flex",
            flexWrap:"wrap",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <div>
            <h4
              style={{
                marginBottom: "0",
                fontFamily: "Margarine sans-serif",
                fontStyle: "normal",
                color: 'aliceblue'
              }}
            >
              Follow Us
            </h4>
          </div>
          <div className="social-icon">
            <a href="https://www.linkedin.com/company/brainalyst/posts/?feedView=all">
              <img src={navicon1} alt="linkedin" width="24px" height="24px"/>
            </a>
            <a href="https://www.facebook.com/Brainalystindia/">
              <img src={navicon2} alt="facebook" width="24px" height="24px"/>
            </a>
            <a href="https://www.instagram.com/brainalyst_india/">
              <img src={navicon3} alt="instagram" width="24px" height="24px"/>
            </a>
            <a href="https://www.youtube.com/channel/UCphzWgX1Inhm3VAaSBDGwuw">
              <img src={navicon4} alt="youtube" width="24px" height="24px"/>
            </a>
          </div>
        </div>      
        
        <div className="copyDiv" style={{ textDecoration: "none", color: "#fff",fontFamily: 'margaine sans-serif',fontWeight: '400',fontStyle: 'normal' }}>© Brainalyst 2021-2024 |  
          <Link 
                to="/privacy-policy"
                style={{ textDecoration: "none", color: "#fff",fontFamily: 'margaine sans-serif',fontWeight: '400',fontStyle: 'normal' }}
              >
                Privacy Policy
              </Link> |  <Link to="/faq" style={{ textDecoration: "none", color: "#fff",fontFamily: 'margaine sans-serif',fontWeight: '400',fontStyle: 'normal' }}>
                 FAQ's
              </Link></div>
      </Container>
    </footer>
  );
};
