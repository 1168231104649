import React, { useState } from "react";
import Odometer from "react-odometerjs";
import { Container, Row, Col, Card } from "react-bootstrap";
import TrackVisibility from "react-on-screen";

export const Project = () => {
  const [value1, setValue1] = useState(1);
  const [value2, setValue2] = useState(1);
  const [value3, setValue3] = useState(1);

  const startCounters = () => {
    setValue1(100);
    setValue2(50);
    setValue3(2);
  };

  return (
    <section className="project" id="project" style={{background: 'rgb(240 236 236 / 81%)'}}>
      <Container>
        <Row>
          <div className="course-title" style={{ width: "100%", textAlign: "center" }}>
              <h2
                  style={{
                    fontWeight: "600",
                    fontFamily: "Margarine, sans-serif",
                    fontStyle: "normal",
                  }}
                  className="animate__animated animate__bounce">
                  Project
                </h2>             
          </div>
          <hr style={{ width: "100%" }} />
          <Col style={{display: "flex",justifyContent: "center"}}>
            <TrackVisibility>
              {({ isVisible }) => {
                if (isVisible) startCounters();
                return (
                  <Card
                    style={{
                      width: "20rem",
                      marginBottom: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontFamily: "Margarine, sans-serif",
                          fontWeight: "400",
                          fontStyle: "normal",
                          textAlign: "center",
                        }}
                      >
                        <h1>
                          <Odometer value={value1} format="(.ddd),dd" />+
                        </h1>
                        Assignments
                      </Card.Title>
                    </Card.Body>
                  </Card>
                );
              }}
            </TrackVisibility>
          </Col>
          <Col style={{display: "flex",justifyContent: "center"}}>
            <TrackVisibility>
              {({ isVisible }) => {
                if (isVisible) startCounters();
                return (
                  <Card
                    style={{
                      width: "20rem",
                      marginBottom: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontFamily: "Margarine, sans-serif",
                          fontWeight: "400",
                          fontStyle: "normal",
                          textAlign: "center",
                        }}
                      >
                        <h1>
                          <Odometer value={value2} format="(.ddd),dd" />+
                        </h1>
                        Industry Relevant Case Studies
                      </Card.Title>
                    </Card.Body>
                  </Card>
                );
              }}
            </TrackVisibility>
          </Col>
          <Col style={{display: "flex",justifyContent: "center"}}>
            <TrackVisibility>
              {({ isVisible }) => {
                if (isVisible) startCounters();
                return (
                  <Card
                    style={{
                      width: "20rem",
                      marginBottom: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontFamily: "Margarine, sans-serif",
                          fontWeight: "400",
                          fontStyle: "normal",
                          textAlign: "center",
                        }}
                      >
                        <h1>
                          <Odometer value={value3} format="(.ddd),dd" />
                        </h1>
                        Capstone Projects
                      </Card.Title>
                    </Card.Body>
                  </Card>
                );
              }}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
