import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import jobprepimg1 from "../img/resume bulding.svg";
import jobprepimg2 from "../img/industry mentorship.svg";
import jobprepimg3 from "../img/aptitude.svg";
import jobprepimg4 from "../img/git.svg";
import jobprepimg5 from "../img/linkedin profile building.svg";
import jobprepimg6 from "../img/mock interview.svg";
import "./Jobprep.css";

export const Jobprep = () => {
  return (
    <section className="Jobprep" id="jobprep">
      <Container>
        <Row>
              <div className="course-title">
                <h2
                  style={{
                    fontWeight: "600",
                    fontFamily: "Margarine sans-serif",
                    fontStyle: "normal",
                  }}
                  className="animate__animated animate__flipInY">
                  Job Preparation
                </h2>
              </div>
          <div style={{display: 'flex',justifyContent: 'center'}}><h6>Land Your Ideal Job Faster with Brainalyst's Expert Preparation</h6></div>
          <hr />
          {[
            { img: jobprepimg1,alt:"jobprepimg1", title: " Resume Building",text: "Craft a standout resume that highlights your skills and experiences to catch employers' eyes" },
            { img: jobprepimg2,alt:"jobprepimg2", title: "Industry Mentorship Session",text:" Gain insider tips and advice from professionals in your field to boost your career prospects" },
            { img: jobprepimg3,alt:"jobprepimg3", title: " General Aptitude Preparation Sessions",text:" Sharpen your problem-solving skills and test strategies to excel in job assessments" },
            { img: jobprepimg4,alt:"jobprepimg4", title: "GitHub Repository Building",text:" Build a GitHub repo to showcase your project to hiring managers" },
            { img: jobprepimg5,alt:"jobprepimg5", title: "LinkedIn Profile Building",text:" Optimize your LinkedIn profile to attract recruiters and network with industry leaders" },
            { img: jobprepimg6,alt:"jobprepimg6", title: "Mock Interview",text:" Practise answering common interview questions with feedback" },
          ].map((course, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "3rem",
              }}
            >
              <Card className="course-card">
                <Card.Img
                  variant="top"
                  src={course.img}
                  alt="jobPrepImg"
                  className="course-img"
                  style={{height: '7rem'}}
                />
                <Card.Body>
                  <Card.Title className="course-title">
                    {course.title}
                  </Card.Title>
                  <Card.Text style={{fontFamily: "Margarine sans-serif",fontStyle: "normal",}}>{course.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
